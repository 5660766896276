import { isNumber } from '@commonstock/common/src/utils/format';
export const ColorMap = {
  BackgroundPrimary: {
    light: '#FFFFFF',
    dark: '#313342'
  },
  BackgroundSecondary: {
    light: '#F2F4FA',
    dark: '#242631'
  },
  BackgroundTertiary: {
    light: '#D3D8E7',
    dark: '#2C2C2E'
  },
  BackgroundSpecial: {
    light: '241.93deg, #6A35FF -10.05%, #16C0D8 114.69%',
    dark: '135deg, #6F44EB 0%, #24AABD 100%'
  },
  BadgeNegative: {
    light: '#FFDAE6',
    dark: '#643655'
  },
  BadgePositive: {
    light: '#C4F5F5',
    dark: '#275A65'
  },
  BadgeDefault: {
    light: '#E6E8EF',
    dark: '#575F6F'
  },
  TextPrimary: {
    light: '#242631',
    dark: '#F2F4FA'
  },
  TextSecondary: {
    light: '#6B7280',
    dark: '#B6BCCB'
  },
  TextTertiary: {
    light: '#9CA5B7',
    dark: '#9CA5B7'
  },
  TextQuartiary: {
    light: '#B6BCCB',
    dark: '#636364'
  },
  TextInverse: {
    light: '#FFFFFF',
    dark: '#1C1C1E'
  },
  TextSpecial: {
    light: '#16C0D8',
    dark: '#16C0D8'
  },
  TextLinks: {
    light: '#895FFE',
    dark: '#AC8EFF'
  },
  TextNegative: {
    light: '#FF3577',
    dark: '#FF6899'
  },
  TextPositive: {
    light: '#05BAA8',
    dark: '#79EEE3'
  },
  TextPrimaryButton: {
    light: '#FFFFFF',
    dark: '#FFFFFF'
  },
  TextSecondaryButton: {
    light: '#242631',
    dark: '#F2F4FA'
  },
  TextSecondaryButtonSelected: {
    light: '#FFFFFF',
    dark: '#F2F4FA'
  },
  /// !
  IconDefault: {
    light: '#6B7280',
    dark: '#B6BCCB'
  },
  IconSubdued: {
    light: '#9CA5B7',
    dark: '#6B7280'
  },
  IconHover: {
    light: '#03B6B6',
    dark: '#0AD3D3'
  },
  SeparatorPrimary: {
    light: '#D3D8E7',
    dark: '#6B7280'
  },
  ActionsPrimaryActive: {
    light: '#0AD3D3',
    dark: '#0AD3D3'
  },
  // !
  ActionsPrimaryHover: {
    light: '#01CACA',
    dark: '#01CACA'
  },
  ActionsPrimaryPressed: {
    light: '#00ABAB',
    dark: '#00ABAB'
  },
  ActionsPrimaryDisabled: {
    light: '#D3D8E7',
    dark: '#6B7280'
  },
  ActionsSecondaryDefault: {
    light: '#D3D8E7',
    dark: '#6B7280'
  },
  ActionsSecondaryHover: {
    light: '#B6BCCB',
    dark: '#474749'
  },
  ActionsSecondaryPressed: {
    light: '#A0A7B9',
    dark: '#5C5C5D'
  },
  ActionsSecondarySelected: {
    light: '#01BEBE',
    dark: '#01BEBE'
  },
  ActionsSecondaryDisabled: {
    light: '#EEEEEF',
    dark: '#333335'
  },
  ActionsDestructiveDefault: {
    light: '#FF0266',
    dark: '#FF1E77'
  },
  ActionsDestructiveHover: {
    light: '#E51B6B',
    dark: '#E51B6B'
  },
  ActionsDestructivePressed: {
    light: '#CC185F',
    dark: '#CC185F'
  },
  ActionsDestructiveDisabled: {
    light: '#EEEEEF',
    dark: '#333335'
  },
  ActionsSpecialDefault: {
    light: 'linear-gradient(241.93deg, #6A35FF -10.05%, #16C0D8 114.69%)',
    dark: 'linear-gradient(241.93deg, #6A35FF -10.05%, #16C0D8 114.69%)'
  },
  ActionsSpecialHover: {
    light: 'linear-gradient(241.93deg, #531CEE -10.05%, #10ADC4 114.69%);',
    dark: 'linear-gradient(241.93deg, #531CEE -10.05%, #10ADC3 114.69%)'
  },
  ActionsSpecialPressed: {
    light: 'linear-gradient(241.93deg, #420DD9 -10.05%, #0D99AC 114.69%)',
    dark: 'linear-gradient(241.93deg, #531CEE -10.05%, #0D99AC 114.69%)'
  },
  ActionsSpecialDisabled: {
    light: '#EEEEEF',
    dark: '#333335'
  },
  ActionsTwitterDefaut: {
    light: '#00A7FF',
    dark: '#00A7FF'
  },
  ChartsPositive: {
    light: '#0AD3D3',
    dark: '#0AD3D3'
  },
  ChartsNegative: {
    light: '#FF0266',
    dark: '#FF598F'
  },
  ChartsBenchmark: {
    light: '#D8D6D6',
    dark: '#6B7280'
  },
  SelectedTabBar: {
    light: '#885EFF',
    dark: '#AC8EFF'
  },
  SelectedTimePeriod: {
    light: '#434343',
    dark: '#F2F4FA'
  },
  // Colors not mapped by Figma
  BrandColor: {
    light: '#22D7D7',
    dark: '#6CE5E5'
  },
  // Aka Emerald
  BackgroundOverlay: {
    light: 'rgb(30 31 32 / 80%)',
    dark: 'rgb(30 31 32 / 80%)'
  },
  ArrowShadow: {
    light: '0px 0px 0.5px rgba(0, 0, 0, 0.2)',
    dark: '0px 0px 0.5px rgba(255, 255, 255, 0.2)'
  },
  DefaultShadow: {
    light: '0px 4px 12px rgba(131, 152, 174, 0.2)',
    dark: '0px 4px 12px rgba(0, 0, 0, 0.2)'
  },
  HoverShadow: {
    light: '0px 4px 12px rgba(131, 152, 174, 0.4)',
    dark: '0px 4px 12px rgba(0, 0, 0, 0.4)'
  },
  ActiveShadow: {
    light: '0px 4px 12px rgba(131, 152, 174, 1)',
    dark: '0px 4px 12px rgba(0, 0, 0, 1)'
  },
  // @NOTE: Removed shadow as it was problematic on DarkMode, waiting for final input on Design
  // RaisedShadow: {
  //   light: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
  //   dark: '0px 0px 2px rgba(0, 0, 0, 0.5), 0px 2px 10px rgba(0, 0, 0, 0.3)'
  // },
  BannerColor: {
    light: 'red',
    dark: 'red'
  },
  EXPERIMENT_CardSecondaryBackground: {
    light: '#F9F9F9',
    dark: '#2D2E3D'
  },
  EXPERIMENT_SearchBackground: {
    light: '#D3D8E7',
    dark: '#313342'
  },
  // @NOTE: Safari requires the second color to be a fully transparent version of the first for the fade to transparent effect to work.
  BackgroundAppLinkScrim: {
    light: 'linear-gradient(0, #FFFFFF 70%, rgba(255, 255, 255, 0) 100%)',
    dark: 'linear-gradient(0, #313342 70%, rgba(49, 51, 66, 0) 100%)'
  }
}; // Colors mapping name to var. This is the object to use in css.

export const Colors: Record<keyof typeof ColorMap, string> = Object.entries(ColorMap).reduce((acc, [k]) => {
  acc[k] = `var(--color-${k})`;
  return acc;
}, ({} as any));
export const FontWeightMap = {
  Weight200: {
    light: 200,
    dark: 200
  },
  Weight400: {
    light: 400,
    dark: 300
  },
  Weight500: {
    light: 500,
    dark: 400
  },
  Weight600: {
    light: 600,
    dark: 500
  }
};
export const FontWeights: Record<keyof typeof FontWeightMap, string> = Object.entries(FontWeightMap).reduce((acc, [k]) => {
  acc[k] = `var(--weight-${k})`;
  return acc;
}, ({} as any));
export const NavBarWidth = '10.375rem';
export const MainContentWidth = '33rem';
export const PanelGridGap = '1rem';
export const SideRailWidth = '33rem';
export const ChatListWidth = '12.5rem';
export const LayoutVariables = {
  NavBarWidth: 'var(--NavBarWidth)',
  MainContentWidth: 'var(--MainContentWidth)',
  SideRailWidth: 'var(--SideRailWidth)',
  ChatListWidth: 'var(--ChatListWidth)'
};
export const HairlineVarName = '--hairline';
export const Hairline = `var(${HairlineVarName})`; // Pane gap + Full Pane lateral padding

const DoublePaneSpacings = `${PanelGridGap} + ${PanelGridGap} + ${PanelGridGap}`; // Full Pane lateral padding

const SinglePaneSpacings = `${PanelGridGap} + ${PanelGridGap}`;
/** Width: 89.875rem (1438px) LoggedIn OR 77.375rem (1238px) LoggedOut */

export const SinglePaneBreakpoint = `calc(${NavBarWidth} + ${MainContentWidth} + ${DoublePaneSpacings} + ${SideRailWidth} + ${ChatListWidth})`;
/** Width: 55.875rem (894px) LoggedIn OR 43.375rem (694px) LoggedOut */

export const MobileBreakpoint = `calc(${NavBarWidth} + ${MainContentWidth} + ${SinglePaneSpacings} + ${ChatListWidth})`;
/** Width: 33rem (528px) */

export const SmallMobileBreakpoint = `calc(${MainContentWidth})`;
export const MobileMaxWidth = `calc(100vw - ${PanelGridGap} - ${PanelGridGap})`;
export const getColor = (value: number | undefined | null) => {
  return !isNumber(value) || value === 0 ? Colors.TextSecondary : value > 0 ? Colors.TextPositive : Colors.TextNegative;
};
export const getChartLineColor = (value: number | undefined | null) => {
  return !isNumber(value) || value === 0 ? Colors.ChartsBenchmark : value > 0 ? Colors.ChartsPositive : Colors.ChartsNegative;
};
export const getTradeColor = (value: number | undefined | null) => {
  return !isNumber(value) || value <= 0 ? Colors.TextLinks : Colors.TextPositive;
};
export const defaultSpringConfig = {
  duration: 0.3
};
export const easeOutTransition = {
  ease: 'easeOut',
  duration: 0.3
};
export const ZIndexLayers = {
  PopoverArrow: -1,
  SlightlyUp: 1,
  SlightlyMoreUp: 2,
  ModeratelyUp: 5,
  Popover: 100,
  Header: 200,
  PaneBackdrop: 300,
  NavBar: 400,
  Chat: 300,
  Modal: 1000,
  DevPill: 9999
};
export enum Theme {
  Light = 'light',
  Dark = 'dark',
}